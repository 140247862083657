.Header {
  font-size: 35px;
  font-weight: 800;
  padding: 10px;
  letter-spacing: 0.4px;
  background-color: cornflowerblue;
  color: black;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 6px black;
}

.roomContainer {
  width: 25%;
  height: 260px;
  border-radius: 8px;
  margin: 0 auto;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;
  box-shadow: 0 1px 10px grey;
  /* margin-bottom: 70px; */
}

.generate {
  display: flex;
  flex-direction: row;
}

.optionIcon {
  height: 32px;
  width: 32px;
  /* padding: 1px; */

  margin-left: 2px;
}

.header {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: 800;
  display: flex;
}

.errmsg {
  background-color: #faa0a0;
  position: fixed;
  width: 21%;
  height: 40px;
  border-radius: 8px;
  top: 20px;
  right: 1.5%;
  font-weight: 500;
  padding-left: 10px;
  /* padding-top: 5px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.optionIconn {
  height: 20px;
  width: 20px;
  padding: 1px;
}

.or {
  font-size: 12px;
  margin-bottom: 2px;
}

.input {
  width: 140px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  /* border: none; */
}

.input::placeholder {
  padding-left: 10px;
}

.generateInput {
  width: 50px;
  padding-left: 8px;
}

.generateInput::placeholder {
  padding-left: 2px;
}

.submit {
  margin-left: 2px;
  height: 20px;
  background-color: cornflowerblue;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 4px black;
}

.generator {
  margin-left: 2px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid black;
}

.roomInput {
  width: 70px;
  /* padding-left: 2px; */
  border-radius: 8px;
  margin-bottom: 10px;
  text-indent: 14px;
}

.roomInput::placeholder {
  /* padding-left: -2px; */
}

.pageContainer {
  width: 100vw;
  height: 100vh;
  background-image: url("../../public/images/backgroundimagee.jpg");
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1000px) {
  .roomContainer {
    width: 40%;
  }
}
@media (max-width: 700px) {
  .roomContainer {
    width: 73%;
  }
}
